import { regionData, codeToText } from "element-china-area-data";
import Clipboard from 'clipboard';
import { Message } from "element-ui";
import Dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween'
// CryptoJS库
import CryptoJS from 'crypto-js';
// 格式化dictCodeToName
export const dictCodeToName = (code, arr) => {
  // console.error(code,arr,'=== dictCodeToName ===');
  if (code && arr && arr.length) {
    let d = arr.find(item => item.id == code||item.key == code || item.school_id == code);
    return d ? (d.label ||d.val || d.full_name || d.name || "") : "";
  }
  return '';
}
// code To name
export const codeToName = (code, arr, separ='、') => {
  if (arr && arr.length) {
    if(typeof code == 'object'&&code.length){
      let nameArr = code.map(item => dictCodeToName(item, arr));
      return nameArr.filter(item => item).join(separ);
    }else if (objectType(code)) {
      let nameArr = JSON.parse(code).map(item => dictCodeToName(item, arr));
      return nameArr.filter(item => item).join(separ);
    } else {
      let d = arr.find((item) => item.id === code);
      return d ? (d.label||d.val || d.full_name || d.name || "") : "";
    }
  }
  return '';
}
// 判断的是否是JSON字符串
export const objectType = (str) => {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str);
      // 等于这个条件说明就是JSON字符串 会返回true
      if (typeof obj == 'object' && obj) {
        return true;
      } else {
        //不是就返回false
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
}
// 判断json对象中所有status属性是否都为true
export const checkAllStatus =(obj) =>{
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === 'status' && obj[key] !== true) {
          return false;
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (!checkAllStatus(obj[key])) {
            return false;
          }
        }
      }
    }
  }
  return true;
  // return Object.keys(obj).every(key => key === 'status' && obj[key] === true);
}
// 数字转为中文数字
export const numberToChinese = (number)=> {
  const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const chineseUnits = ['', '十', '百', '千', '万', '亿'];

  let result = '';
  let numStr = number.toString();
  for (let i = 0; i < numStr.length; i++) {
    let digit = parseInt(numStr[i]);
    let unit = chineseUnits[numStr.length - i - 1];
    if (digit === 0) {
      if (result[result.length - 1] !== '零') {
        result += chineseNumbers[digit];
      }
    } else {
      result += chineseNumbers[digit] + unit;
    }
  }
  return result;
}
// 格式化菜单类型
export const formatMenuType = (n)=> {
  let type = '';
  switch(n){
    case 1: // 目录
      type='warning'
      break;
    case 2: // 菜单
      type='success'
      break;
    case 3: // 按钮
      type='danger'
      break;
    case 4: // 外链
      type='primary'
      break;
    case 5: // 页面
      type='info'
      break;
    default:
  }
  return type;
}
// 转换数据类型
export const convertToRealDataType = (obj)=> {
  for (let key in obj) {
    if (typeof obj[key] === 'string') {
      if (obj[key] === 'true') {
        obj[key] = true;
      } else if (obj[key] === 'false') {
        obj[key] = false;
      } else if (!isNaN(obj[key])) {
        obj[key] = Number(obj[key]);
      }
    } else if (typeof obj[key] === 'object') {
      convertToRealDataType(obj[key]);
    }
  }
}
// 区域
export const areaOptionData = () => {
  return [...regionData];
}
// 区域格式化
export const formatAreaCodeToText = (codeStr) => {
  // console.log(codeStr, "codeStr");
  if (objectType(codeStr)) {
    let codeArray = JSON.parse(codeStr);
    return codeArray && codeArray.length ? codeArray.map((item) => codeToText[item]).join("/") : "";
  } else {
    return codeStr;
  }
}
// 区域formatTextToCode
export const formatAreaTextToCode = ({ province, city, district }) => {
  if (province == city) {
    city = '市辖区'
  }
  let p = regionData.find(item => item.label == province) || {};
  let c = p && p.children ? p.children.find(item => item.label == city) : {};
  let d = c && c.children ? c.children.find(item => item.label == district) : {};
  return [p.value, c.value, d.value].filter(item => item);
}
// el-table内容自适应方法  flexWidth
export const flexWidth = (prop, tableData, title, num = 0) =>{
  if (tableData.length === 0) {
    // 表格没数据不做处理
    return
  }
  let flexWidth = 0 // 初始化表格列宽
  let columnContent = '' // 占位最宽的内容
  let canvas = document.createElement('canvas')
  let context = canvas.getContext('2d')
  context.font = '14px Microsoft YaHei'
  if (prop === '' && title) {
    // 标题长内容少的，取标题的值,
    columnContent = title
  } else {
    // 获取该列中占位最宽的内容
    let index = 0
    for (let i = 0; i < tableData.length; i++) {
      const now_temp = tableData[i][prop] + ''
      const max_temp = tableData[index][prop] + ''
      const now_temp_w = context.measureText(now_temp).width
      const max_temp_w = context.measureText(max_temp).width
      if (now_temp_w > max_temp_w) {
        index = i
      }
    }
    columnContent = tableData[index][prop]
    // 比较占位最宽的值跟标题、标题为空的留出四个位置
    const column_w = context.measureText(columnContent).width
    const title_w = context.measureText(title).width
    if (column_w < title_w) {
      columnContent = title || '占位符呀'
    }
  }
  // 计算最宽内容的列宽
  let width = context.measureText(columnContent)
  flexWidth = width.width + 40 + num
  return flexWidth + 'px';
}
// 复制
export const clipboardCopy = (el, text) => {
  const clipboard = new Clipboard(`#${el}`, {
    text: () => `${text}`,
  });
  clipboard.on('success', (e) => {
    Message.success("已复制！");
    clipboard.destroy();
  });
  clipboard.on('error', (e) => {
    Message.success("复制失败!");
    clipboard.destroy();
  });
}

// 当前时间与目标时间之前
export const checkDayisBefore = (targetTime) => {
  return Dayjs().isBefore(Dayjs(targetTime));
}
// 当前时间与目标时间之后
export const checkDayisAfter = (targetTime) => {
  return Dayjs().isAfter(Dayjs(Dayjs(targetTime).add(1,'day')));
}
// 当前时间加上差值如果大于目标时间则是过期
export const checkTimeisExpired = (diff,targetTime)=>{
  // 获取当前时间  
  const now = Dayjs();
  // 将差值(分钟)添加到当前时间【minute (分钟)】
  const timeAfterDiffMinutes = now.add(diff, 'minute');
  // 将给定的时间戳转换为 Day.js 对象  
  const givenTime = Dayjs(targetTime);
  return timeAfterDiffMinutes.isAfter(givenTime) || timeAfterDiffMinutes.isSame(givenTime); 
}
// 计算剩余时间
export const calculateRemainingTime = (targetTime) => {
  const currentTime = Dayjs();
  const remainingTime = Dayjs(targetTime).diff(currentTime);

  const days = Math.floor(remainingTime / (24 * 60 * 60 * 1000));
  const hours = Math.floor((remainingTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds
  };
}
// 当前时间是否介于两个时间之间
export const checkIsBetween = (begin,end) => {
  if(begin&&end){
    Dayjs.extend(isBetween);
    return Dayjs().isBetween(Dayjs(begin), Dayjs(end).add(1,'day'));
  }
  return false
}
// 格式化时长（ durationInMilliseconds 毫秒 ）
export const formatDuration = (durationInMilliseconds) => {
  const days = Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24));  
  const hours = Math.floor((durationInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));  
  const minutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));  
  const seconds = Math.floor((durationInMilliseconds % (1000 * 60)) / 1000);
  // if(days){
  //   return `<span class="days">${String(days).padStart(2, '0')}天</span><span class="hours">${String(hours).padStart(2, '0')}时</span><span class="minutes">${String(minutes).padStart(2, '0')}分</span><span class="seconds">${String(seconds).padStart(2, '0')}秒</span>`;
  // }else if(hours){
  //   return `<span class="hours">${String(hours).padStart(2, '0')}时</span><span class="minutes">${String(minutes).padStart(2, '0')}分</span><span class="seconds">${String(seconds).padStart(2, '0')}秒</span>`;
  // }else if(minutes){
  //   return `<span class="minutes">${String(minutes).padStart(2, '0')}分</span><span class="seconds">${String(seconds).padStart(2, '0')}秒</span>`;
  // }else if(seconds){
  //   return `<span class="seconds">${String(seconds).padStart(2, '0')}秒</span>`;
  // }else{
  //   return '0秒';
  // }
  if(days){
    return `${String(days).padStart(2, '0')}天${String(hours).padStart(2, '0')}时${String(minutes).padStart(2, '0')}分${String(seconds).padStart(2, '0')}秒`;
  }else if(hours){
    return `${String(hours).padStart(2, '0')}时${String(minutes).padStart(2, '0')}分${String(seconds).padStart(2, '0')}秒`;
  }else if(minutes){
    return `${String(minutes).padStart(2, '0')}分${String(seconds).padStart(2, '0')}秒`;
  }else if(seconds){
    return `${String(seconds).padStart(2, '0')}秒`;
  }else{
    return '0秒';
  }
  // return `${String(days).padStart(2, '0')}天${String(hours).padStart(2, '0')}时${String(minutes).padStart(2, '0')}分${String(seconds).padStart(2, '0')}秒`;
}

// 成绩等级颜色
export const gradeLevel = [
  { label: '优秀', color: 'primary', max: 100, min: 85 },
  { label: '良好', color: 'success', max: 85, min: 70 },
  { label: '合格', color: 'info', max: 70, min: 60 },
  { label: '不合格', color: 'danger', max: 60, min: 0 }
]
// 处理数字到最近的整数(默认保留两位小数)
export const toNearestInteger = (num, decimalPlaces=2, mode='round')=>{
  let factor = Math.pow(10, decimalPlaces);  
  if (mode === 'round') {  
    // 四舍五入到最近的整数
    return Math.round(num * factor) / factor;  
  } else if (mode === 'floor') {  
    // 向下取整到最近的整数
    return Math.floor(num * factor) / factor;  
  }
}
// 进度条颜色
export const customProgressColors = [
  { color: '#909399', percentage: 20 },
  { color: '#f56c6c', percentage: 40 },
  { color: '#e6a23c', percentage: 60 },
  { color: '#409eff', percentage: 80 },
  { color: '#00bcd4', percentage: 100 },
  { color: '#67c23a', percentage: 100 },
]
// 随机生成颜色（RGBA）
export const generateRandomColor = (alpha = 1) => {  
  let r = Math.floor(Math.random() * 256);  
  let g = Math.floor(Math.random() * 256);  
  let b = Math.floor(Math.random() * 256);  
  return 'rgba(' + r + ',' + g + ',' + b + ',' + alpha + ')';  
}
// 文件字节大小（返回带单位值）
export const formatBytes = (bytes) => {
  if (bytes >= 1024 * 1024 * 1024) {
    return toNearestInteger(bytes / (1024 * 1024 * 1024)) + 'GB';
  } else if (bytes >= 1024 * 1024) {
    return toNearestInteger(bytes / (1024 * 1024)) + 'MB';
  } else if (bytes >= 1024 ) {
    return toNearestInteger(bytes / 1024) + 'KB';
  } else {
    return bytes + 'B';
  }
}
// url 拼接参数
export const appendQueryParams = (url='', params='') => {
  // 如果url不是 blob 开头的则拼接参数
  if(!/^blob:/.test(url)){
    const mark = url.includes('?') ? '&' : '?';
    // 如果params是字符串，直接拼接  
    if (typeof params === 'string') {  
      return `${url}${mark}${params}`;  
    }  
    // 如果params是对象，遍历并拼接  
    if (typeof params === 'object') {  
      const queryParams = Object.entries(params).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');  
      return `${url}${mark}${queryParams}`;  
    }
  }else{
    return url;
  }
}
// 提取URL中文件信息
export const extractFileInfoFromURL= (url='')=> {
  let directory = '';
  let filename = '';
  if (url.startsWith('http')) {
    const { pathname } = new URL(url);
    url = pathname;
  }
  // 正则表达式匹配文件目录和文件名  
  const result = url.match(/^(.*?\/)([^\/]+)$/);
  if (result && result.length) {
    // result[1] 是文件目录，result[2] 是文件名  
    directory = result[1];
    filename = result[2];
  }
  // 匹配文件后缀
  const suffixMatch = filename.match(/(\.[^.]+)$/);
  const filetype = suffixMatch ? suffixMatch[1] : '';
  return { directory, filename, filetype };
}
// 确保数组对象
export const ensureArray = (value) => {
  if (value&&Array.isArray(value)) {
    return value;
  } else if (value&&Object.keys(value).length) {
    return Array(value)
  } else {
    return []
  }
}
export const tagType = (key)=>{
  const types = {
    '3':'success',
    '优':'success',
    '2':'primary',
    '良':'primary',
    '1':'info',
    '中':'info',
    '0':'info',
    '无':'info',
    '合格':'info',
    '待合格':'danger',
  }
  return types[key]||'primary'
}

// URL 参数加密方法
const secretKey = 'q1w2e3r4t5y6u7i8o9p0'; // 加密密钥
export const encryptParam = (param) => {
  return CryptoJS.AES.encrypt(param.toString(), secretKey).toString();
}
// URL 参数解密方法
export const decryptParam = (encryptedParam) => {
  const bytes = CryptoJS.AES.decrypt(encryptedParam, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}