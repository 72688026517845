import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):null,
    menu: localStorage.getItem('menu')?JSON.parse(localStorage.getItem('menu')):null,
    permission: localStorage.getItem('permission')?JSON.parse(localStorage.getItem('permission')):null,
    keepAlivePageName:[],
    routeSource: null,
    // 存放的键值对就是所要管理的状态(指标题库)
    scene : 'admin',
    focus : 0,
    id : '',
    title : '',
    description : '',
    list:[],
    currentpage : 1,
    pagesize : 0,
    mk : '',
    zb : '', 
    wdlist : [],
  },
  getters: {
    userInfo (state) {
      return state.user ? state.user : {
        profile_photo:'',
        id: '',
        name: '',
        sex:'',
        username:'',
        phone: '',
        email: '',
        remark:'',
        select_role: '',
        roles:[],
        status: '',
        created_by: '',
        from_type:'',
        updated_by: '',
        wx_openid: '',
        deleted_at:'',
      };
    },
    username (state) {
      return state.user ? state.user.username : "";
    },
    email (state) {
      return state.user? state.user.email : "";
    },
    roleInfo (state) {
      return state.user? state.user.roles : [];
    },
    menu (state) {
      return state.menu ? state.menu : [];
    },
    permission (state) {
      return state.permission ? state.permission : [];
    },
    keepAlivePageName (state) {
      return state.keepAlivePageName ? state.keepAlivePageName : [];
    },
    // 指标题库
    cur_page_start (state) {
      const {pagesize=0, currentpage=1} = state;
      return  pagesize * (currentpage - 1)
    },
    curList (state) {
      return state.list.slice((state.currentpage-1)*state.pagesize, state.currentpage*state.pagesize)
    },
    completed (state) {
      let completed = true;
      state.list.forEach((item={}, index, array)=>{
        const {isPassed,config={}} = item;
        if(config.rules.required.use){
          completed &= isPassed
        }
      })
      return completed;
    }
  },
  mutations: {
    SET_USER (state, val) {
      state.user = val;
    },
    SET_MENU (state, val) {
      state.menu = val;
    },
    SET_PERMS (state, val) {
      state.permission = val;
    },
    SET_SIDE_BAR (state, val) {
      state.sideBar = val;
    },
    SET_KEEP_ALIVE_PAGE_NAME(state, val){
      state.keepAlivePageName = val
    },
    SET_ROUTE_SOURCE(state, val) {
      state.routeSource = val;
    },
    // 指标题库
    init(state, data={}){
      const {id='',title='',mk_id='',zb_id='',content='[]',description='',pagesize=0} = data;
      state.id = id
      state.title = title
      state.description = description
      state.list = JSON.parse(content||'[]')
      state.pagesize = pagesize
      state.mk = mk_id
      state.zb = zb_id
      state.timestamp = new Date()
    },
    // setWJuser(state,val){
    //   state.wjuser = val;
    // },
    setWDlist(state,val){
      state.wdlist = val;
    },
    setScene(state,val){
      state.scene = val;
    },
    focus(state, index=0){
      state.focus = index
      if(state.pagesize > 0){
         const page = Math.ceil((index + 1) / state.pagesize);
          if(page != state.currentpage){
             state.currentpage = page;
          } 
      }
    },
    setCurPage(state, curpage=1){
      state.currentpage = curpage;
      state.focus = state.pagesize * (state.currentpage - 1)
    },
    resort(state, newlist){
        state.list = newlist;
    },
    append(state, item){
      state.list.push(item);
    },
    remove(state, index){
      state.list.splice(index, 1);
    },
    copy(state, index){
      //https://www.lodashjs.com/docs/lodash.cloneDeep
      const data = state.list[index];
      const newData = _.cloneDeep(data);
      const val = data.respondent.answer.value;
      newData.isPassed = false;
      newData.respondent.answer.value = Array.isArray(val)?[]:'';
      state.list.splice(index+1, 0, newData);
    },
    title(state, payload){
      console.log('payload', payload.index)
      state.list[payload.index].config.title = payload.title
    },
    removeOptions(state, payload){
      state.list[payload.index].config.options.splice(payload.optionIndex, 1)
    },
    updateMedia(state, payload){
      let options = state.list[payload.index].config.options
      options[payload.optionIndex].media = payload.media
    },
  },
  actions: {
    setUser ({ commit }, val) {
      commit("SET_USER", val);
    },
    setMenu ({ commit }, val) {
      commit("SET_MENU", val);
    },
    setPerms ({ commit }, val) {
      commit("SET_PERMS", val);
    },
    setSideBar ({ commit }, val) {
      commit("SET_SIDE_BAR", val);
    },
    setKeepAlivePageName ({ commit }, val) {
      commit("SET_KEEP_ALIVE_PAGE_NAME", val);
    },
    setRouteSource ({ commit }, val) {
      commit("SET_ROUTE_SOURCE", val);
    },
  },
  modules: {
  }
})
